<template>
  <div class="root">
    <el-card>
      <h3>修改密码</h3>
      <el-divider></el-divider>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            v-model="ruleForm.oldPassword"
            :type="oldPasswordFlag ? 'password' : 'text'"
          >
            <i
              slot="suffix"
              :class="oldPasswordFlag ? 'el-icon-minus' : 'el-icon-view'"
              style="margin-top: 8px; font-size: 18px"
              @click="oldPasswordFlag = !oldPasswordFlag"
          /></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="ruleForm.newPassword"
            :type="newPasswordFlag ? 'password' : 'text'"
          >
            <i
              slot="suffix"
              :class="newPasswordFlag ? 'el-icon-minus' : 'el-icon-view'"
              style="margin-top: 8px; font-size: 18px"
              @click="newPasswordFlag = !newPasswordFlag"
          /></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPassword1">
          <el-input
            v-model="ruleForm.newPassword1"
            :type="newPassword1Flag ? 'password' : 'text'"
            ><i
              slot="suffix"
              :class="newPassword1Flag ? 'el-icon-minus' : 'el-icon-view'"
              style="margin-top: 8px; font-size: 18px"
              @click="newPassword1Flag = !newPassword1Flag"
          /></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { sessionGet } from "@/utils/local.js";
import { updatePwd_api } from "@/api/user.js";
import md5 from "js-md5"
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入旧密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      oldPasswordFlag: true,
      newPasswordFlag: true,
      newPassword1Flag: true,
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        newPassword1: "",
        userId: sessionGet("userId"),
      },
      rules: {
        oldPassword: [{ validator: validatePass, trigger: "blur" }],
        newPassword: [{ validator: validatePass2, trigger: "blur" }],
        newPassword1: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    async updatePwd() {
      let {code,msg} = await updatePwd_api({
        oldPassword:md5(this.ruleForm.oldPassword),
        newPassword:md5(this.ruleForm.newPassword),
        newPassword1:md5(this.ruleForm.newPassword1),
        userId:this.ruleForm.userId,
      });
      if(code===200){
        this.$message({
          message: '恭喜你，密码修改成功请重新登录',
          type: 'success'
        });
        this.$router.push('/login')
      }else{
        this.$message.error(msg)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) this.updatePwd();
      });
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.root {
  .el-card {
    margin-bottom: 20px;
    h3 {
      font-weight: 700;
    }
    .el-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 30px 50%;
      transform: translateX(-50%);
    }
    .el-form {
      margin-left: 25%;
      width: 50%;
    }
  }
}
</style>